<template>
	<div class="calendar-column" ref="column"
	:id="`column-${date.toLocaleDateString()}`"
	v-bind:style="{'min-width': width}">

		<!-- Ceils -->
		<div class="calendar-column-grid">
			<div class="grid-ceil" 
			v-for="hour in workTime.range()" 
			:key="`hour-${date.toLocaleDateString()}-${hour}`"
			v-bind:style="{
				height: cellHeight + 'px',
			}">
			</div>
		</div>

		<!-- Slots -->
		<div class="calendar-column-slots" v-if="slotViewType">
			<BaseSlotCard
				v-for="slot in slots"
				:key="`slot-${slot.index}`"
				:eventSlot="slot"
				:slotContainer="slotContainer"
				:workTime="workTime"
				:cellHeight="cellHeight"
				:isReplaceable="isEditable"
				:slotCollection="slotCollection"
				:columnCount="columnCount"
				:viewType="slotViewType"
				:dateIsSelected="dateIsSelected"
			/>

			<QuestionSlotCard
				v-for="slot, index in slotCollection.getQuestionsByDate(date)"
				:key="`question-slot-${date.toLocaleDateString()}-${index}`"
				:eventSlot="slot"
				:slotContainer="slotContainer"
				:workTime="workTime"
				:cellHeight="cellHeight"
				:columnCount="columnCount"
				:slotCollection="slotCollection"
			/>

			<NearestPlaceClue
				:period="nearestFreePlace"
				:cellHeight="cellHeight"
				:workTime="workTime"
			/>
		</div>		
	</div>
</template>

<script>
import CalendarHeaderItem from '../../scripts/calendarHeaderItem';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import SlotCollection from '../../scripts/slotCollection';
import BaseSlotCard from './BaseSlotCard.vue';
import QuestionSlotCard from './QuestionSlotCard.vue';
import PastTimeBackdrop from './PastTimeBackdrop.vue';
import { cssVh, cssVw } from '../../scripts/cssUnits';
import ColumnDateTitle from './CalendarDateTitle.vue';
import NearestPlaceClue from './NearestPlaceClue.vue';
import eventBroker from './eventBus';

const SLOT_VIEW_SIZES = [
	{
		width: 200,
		type: "full"
	},
	{
		width: 130,
		type: "middle",
	},
	{
		width: 0,
		type: "short",
	},
]

export default {
	name: 'Column',

	components: {
		PastTimeBackdrop,
		ColumnDateTitle,
		BaseSlotCard,
		NearestPlaceClue,
		QuestionSlotCard,
	},

	props: {
		date: {
			type: Date,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		slotContainer: {
			type: HTMLElement,
		},
		width: {
			type: String,
			default: `100%`,
		},
		cellHeight: {
			type: Number,
			default: 54,
		},
		columnCount: {
			type: Number,
			default: 1,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		dateIsSelected: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		slots(){
			this.slotCollection.loadingCount
			return this.slotCollection.getByDate(this.date)
		},
	},

	watch: {
		date(newValue){
			const columnTitle =`column-${newValue.toLocaleDateString()}` 
			eventBroker.$off(columnTitle)
			eventBroker.$on(columnTitle, 'define-nearest-free-place', this.nearestFreePlaceHandler)
		}
	},

	data(){
		return {
			columnWidth: 0,
			slotViewType: null,

			nearestFreePlace: null,
		}
	},

	created(){
		const columnTitle =`column-${this.date.toLocaleDateString()}` 
		eventBroker.$on(columnTitle, 'define-nearest-free-place', this.nearestFreePlaceHandler)
	},

	mounted() {
		this.columnWidthUpdater()
		window.addEventListener('resize', this.columnWidthUpdater)
	},

	updated() {
		this.columnWidthUpdater()
		// const columnTitle =`column-${this.column.date.toLocaleDateString()}` 
		// eventBroker.$off(columnTitle)
		// eventBroker.$on(columnTitle, 'define-nearest-free-place', this.nearestFreePlaceHandler)
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.columnWidthUpdater)

		const columnTitle =`column-${this.date.toLocaleDateString()}` 
		eventBroker.$off(columnTitle, 'define-nearest-free-place', this.nearestFreePlaceHandler)
	},

	methods: {
		calcColumnWidth(){
			const elem = this.$el
			const width = elem.offsetWidth

			if (width != 0) {
				const closestType = SLOT_VIEW_SIZES.map(view => {
					return {
						type: view.type,
						diff: Math.abs(width - view.width)
					}
				}).sort((a, b) => a.diff - b.diff)[0].type
				this.slotViewType = closestType
			}

			return Math.round(width)
		},
		columnWidthUpdater(){
			this.columnWidth = this.calcColumnWidth()
			if (this.columnWidth == 0)
				setTimeout(this.columnWidthUpdater, 10)
		},
		nearestFreePlaceHandler(period){

			if (!period) {
				this.nearestFreePlace = null
				return 
			}

			this.nearestFreePlace = this.slotCollection.nearestFreePlace(period)
		},
	},
};
</script>

<style scoped>

.calendar-column {
	box-sizing: border-box;	
	position: relative;
	height: fit-content;
}

.calendar-column.full {
	margin-right: 15px;
}

.calendar-column-slots {
	width: 100%;
	height: inherit;
}

.calendar-column-grid {
	width: 100%;
	/* height: 100%; */
}

.grid-ceil {
	width: 100%;
}

.grid-ceil:first-child {
	border-top: 1px solid rgb(235, 235, 235); 
}

.grid-ceil + .grid-ceil   {
	border-top: 1px solid rgb(235, 235, 235); 
}

.calendar-column + .calendar-column {
	border-left: 1px solid rgb(235, 235, 235); 
}

</style>