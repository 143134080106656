<template>
	<div class="slot-card__move-behavior" 
	ref="calendar-slot"
	v-bind:class="{
		'is-dragging': !!dragging,
		'is-invalid': timeIsWrong || dateIsWrong,
	}"
	v-bind:style="{...styles}">
		<SlotCardTimeString 
			:slotCard="slotCard" 
			:isVisible="!!dragging"
			:posX="left"
			:isInvalid="timeIsWrong || dateIsWrong"
			:currentHour="currentHour"
			:currentMinute="currentMinute"
			:nearestPlace="nearestPlace"
		/>
		<slot></slot>
	</div>
</template>

<script>
import CalendarWorkTime from '../../../scripts/calendarWorkTime';
import { dateIsPast, isEqualDate, isToday } from '../../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../../scripts/draggable';
import { scrollByCondition } from '../../../scripts/scroll';
import Slot from '../../../scripts/slot';
import SlotCardTimeString from './SlotCardTimeString.vue';
import { calcSlotCardPosY, eventToCoordinates, eventToOffset, eventToPageCoordinates } from '../../../scripts/slotCardPosition';
import TimePeriod from '../../../scripts/timePeriod';
import eventBroker from '../eventBus';




export default {
	name: 'SlotCardMoveBehavior',
	components: {
		SlotCardTimeString
	},

	emits: [
		'on-move-start',
		'on-move',
		'on-move-end'
	],

	props: {
		eventSlot: {
			type: Slot,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		cellHeight: {
			type: Number,
			default: 54,
		},
		height: {
			type: Number,
			default: 54,
		},
		slotCard: HTMLElement,
		slotContainer: HTMLElement,
		currentHour: {
			type: Number, 
			required: true,
		},
		currentMinute: {
			type: Number, 
			required: true,
		},
		dragDelay: {
			type: Number,
			default: 300,
		},
		scrollStep: {
			type: Number,
			required: true,
		},
		isReplaceable: {
			type: Boolean,
			default: true,
		},
		timeIsWrong: {
			type: Boolean,
			default: false,
		},
		dateIsWrong: {
			type: Boolean,
			default: false,
		},
	},
	
	computed: {

		top(){
			return Math.floor(this.posY)
		},
		left(){
			return Math.floor(this.posX)
		},
		styles(){
			return {
				left: this.left + 'px',
				top: this.top + 'px',
			}
		},
		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		}
	},

	data() {
		return {
			posY: 0,
			originY: 0,
			posX: 0,
			originColumnX: 0,
			isTouched: false,
			dragging: null,
			dragLimits: null,
			containerSizes: null,

			width: 0,
			futureColumn: null,
			columnsX: null,

			nearestPlace: null,

			// Scroll container by slot card

			scrollInterval: null,
			scrollSpeedX: null,
			scrolledX: 0,
			scrollSpeedY: null,
			isScrollBorderAreaX: false,
			isScrollBorderAreaY: false,
			leftUpdatedLimit: 0,
			lastMoveEvent: null,
		};
	},

	watch: {
		slotCard(value){
			if (this.isReplaceable) {
				makeDraggable(this.slotCard, {
					start: this.pointerDownHandler,
				});
			}
		},
	},

	created() {
		this.posY = calcSlotCardPosY(this.eventSlot.period.start.date, this.workTime, this.cellHeight)
		this.originY = this.posY
	},

	mounted(){
		if (this.isReplaceable) {
			makeDraggable(this.slotCard, {
				start: this.pointerDownHandler,
			});

		}
		if (this.slotCard) {
			this.width = this.slotCard.offsetWidth
		}

	},
	
	updated(){
		if (this.slotCard)
			this.width = this.slotCard.offsetWidth
	},

	beforeDestroy() {
		if (this.isReplaceable) {
			makeUndraggable(this.slotCard, {
				start: this.pointerDownHandler,
				move: this.pointerMoveHandler,
				end: this.pointerUpHandler,
			});
		}
	},

	methods: {
		containerScrollCheckers(initPos, onScrollDetected, onScrollNotDetected) {
			let isScrolled = false

			const move = (moveEvent) => {
				const move = eventToCoordinates(moveEvent);
				const deltaX = Math.abs(move.x - initPos.x);
				const deltaY = Math.abs(move.y - initPos.y);

				const movedEnough = Math.max(deltaX, deltaY) > 5;
				if (movedEnough) {
					isScrolled = true;
					onScrollDetected()
				}
			}

			const end = () => {
				onScrollNotDetected()
			}

			return {
				move,
				end,
				isScrolled: () => isScrolled,
				enable: () => {
					window.addEventListener('touchmove', move);
					window.addEventListener('touchend', end);
				},
				disable: (names=['touchmove', 'touchend']) => {
					if (names.includes('touchmove'))
						window.removeEventListener('touchmove', move);
					if (names.includes('touchend'))
						window.removeEventListener('touchend', end);
				},
			}
		},

		pointerDownHandler(event) {
			event.preventDefault(); // needed to disable the Touch event and scroll by user touch
			if (this.eventSlot.settings.isQuestionSlot
			||	!this.slotContainer) {
				return;
			}

			this.isTouched = true;
			const initPos = eventToCoordinates(event);
			const scrollCheckHandlers = this.containerScrollCheckers(initPos, 
				() => {
					scrollCheckHandlers.disable(['touchmove']);
					disableTimeout();		
				}, 
				() => {
					scrollCheckHandlers.disable();
					disableTimeout();
			});

			const timeout = setTimeout(() => {

				// Disable the support listeners
				scrollCheckHandlers.disable();

				// If is no touch or it's scroll, then return
				const isScrolled = scrollCheckHandlers.isScrolled();
				const prepareIsFailed = !this.replacePreparation(event);
				if (!this.isTouched || isScrolled || prepareIsFailed) {
					return;
				}

				// Begin the drag
				this.$emit('on-move-start');
				if (window.navigator.vibrate){
					window.navigator.vibrate(65)
				}

				// Activate the drag
				makeDraggable(this.slotCard, {
					move: this.pointerMoveHandler,
					end: this.pointerUpHandler,
				});
				eventBroker.$emit('scrollable-table', 'enable-slot-move-scroll', this.updateLimits)

			}, this.dragDelay);

			// Check if the pointer is moved enough to activate the scroll
			const disableTimeout = () => {
				clearTimeout(timeout);
				this.isTouched = false;
			}


			scrollCheckHandlers.enable();
		},

		updateLimits(reachedLimit, addedColumnsCount) {

			// This function will be called by ScrollableTable when the moving slot
			// will reach left or right limit of scrollable table loaded part.   
		
			const allowedLimits = [
				'left', 
				'right'
			]

			if (!reachedLimit || !allowedLimits.includes(reachedLimit)) {
				throw Error("error : undefined limit is reached by slot card during a replace")
			}

			if (!this.dragging) {
				throw Error("error : standing slot card has no replace and limits for them")
			}


			// If reached limit of loaded scrollable table is right

			if (reachedLimit == 'right') {
				
				// Add relative placement of added columns to the end of array
				
				const lastOldColumn = this.columnsX[this.columnsX.length - 1] 
				const addedColumnsPosX = new Array(addedColumnsCount).fill(0)
				.map((_, index) => {
					return lastOldColumn + (index + 1) * (this.width + 1)
				})
				this.$set(this, 'columnsX', [...this.columnsX, ...addedColumnsPosX])
			}

			// If reached limit of loaded scrollable table is left

			if (reachedLimit == 'left' && this.scrollInterval) {
				
				// Add relative placement of added columns to the begin of array
				
				const firstOldColumn = this.columnsX[0] 
				const addedColumnsPosX = new Array(addedColumnsCount).fill(0)
				.map((_, index) => {
					return firstOldColumn - (addedColumnsCount - index) * (this.width + 1)
				})
				this.$set(this, 'columnsX', [...addedColumnsPosX, ...this.columnsX])

				// Recreate scroll interval because scroll interval contain old scrollLeft value of the scrollable table 

				clearInterval(this.scrollInterval)
				this.scrollInterval = null
				this.scrollContainer(this.posY, this.posX)
			}
			
		},

		setupColumnsPosX(){
			this.columnsX = Array.from(this.slotContainer.querySelectorAll(".calendar-column"))

			const currentColumn = this.columnsX.find((columnElem) => {
				return columnElem.id == `column-${this.eventSlot.period.start.date.toLocaleDateString()}`
			})

			this.originColumnX = currentColumn.getBoundingClientRect().left

			this.columnsX = this.columnsX.map((columnElem) => {
				return Math.round(columnElem.getBoundingClientRect().left - this.originColumnX)
			})

		},

		replacePreparation(event){

			if (!this.slotContainer)
				return false

			// Init table sizes
			let containerSizes = this.slotContainer.getBoundingClientRect()
			let elementSizes = this.$el.getBoundingClientRect()

			let sizes = {}
			sizes["scroll-area-y"] = this.slotContainer.scrollHeight
			sizes["height"] = Math.round(containerSizes.height - elementSizes.height)
			sizes["width"] = Math.round(containerSizes.width - elementSizes.width)

			const hoursContainer = this.slotContainer.querySelector('.calendar_hours_ruler')
			const hoursContainerSizes = hoursContainer.getBoundingClientRect()
			sizes["left"] = hoursContainerSizes.width + containerSizes.left
			this.containerSizes = sizes

			const limits = {}
			limits["top"] = 0
			limits["bottom"] = this.containerSizes["scroll-area-y"] - this.height - 10 - 13 - this.cellHeight
			limits["left"] = elementSizes.left * -1 + this.containerSizes["left"]
			limits["right"] = this.containerSizes["width"] + limits["left"] - hoursContainerSizes.width
			this.dragLimits = limits

			this.setupColumnsPosX()

			// Init start position of a drag and drop
			const pageCoord = eventToPageCoordinates(event)
			const offset = eventToOffset(event)
			let posX = pageCoord.x - offset.x + this.dragLimits["left"] - 40
			let posY = pageCoord.y - offset.y - containerSizes.top  - 32
			
			// Init start point 
			let {x, y} = eventToCoordinates(event);

			this.dragging = {dx: posX - x, dy: posY - y}

			this.originY = posY

			return true
		},

		pointerMoveHandler(event){
			if (!this.dragging) {
				event.preventDefault();
				return
			}
			this.lastMoveEvent = event

			const {x, y} = eventToCoordinates(event);
			
			let posX = x + this.dragging.dx + this.scrolledX + this.leftUpdatedLimit
			let posY = y + this.dragging.dy + this.slotContainer.scrollTop

			// posX = Math.round(Math.max(this.dragLimits["left"], Math.min(posX, this.dragLimits["right"])))
			posY = Math.round(Math.max(this.dragLimits["top"], Math.min( posY, this.dragLimits["bottom"])))

			// const isMovingDown = this.posY < posY
			// const isMovingRight = this.posX < posX
      		this.posY = posY 
			this.posX = posX

			this.scrollContainer(this.posY, this.posX)

			if (!this.isScrollBorderAreaX){
				this.futureColumn = this.calcFutureColumn(posX)
			}

			if (!this.scrollInterval){
				this.sendMoveEvent(posX, posY)
			}

		},

		sendMoveEvent(posX, posY){
			
			this.$emit('on-move', {
				posX, posY, 
				futureColumnDate: this.futureColumn,
			})

			if (this.slotCollection && this.timeIsWrong) {
				const originDate = this.futureColumn || this.eventSlot.period.start.date
				const date = new Date(originDate)
				date.setHours(this.currentHour, this.currentMinute)
				const period = new TimePeriod(date, -1, this.eventSlot.period.duration)
				this.nearestPlace = this.slotCollection.nearestFreePlace(period)
			} else {
				this.nearestPlace = null
			}
		},

		getScrollSpeedY(relativeY, scrollBuffer, contHeight, ){
			
			// Scroll up container (if slot in scroll up area)
			if (relativeY - this.cellHeight <= scrollBuffer)
				return Math.floor(Math.abs(relativeY - this.cellHeight) / this.scrollStep) * -1
			
			// Scroll down container (if slot in scroll down area)
			if (relativeY - contHeight + this.cellHeight >= scrollBuffer)
				return Math.floor(Math.abs(relativeY - contHeight + this.cellHeight) / this.scrollStep) + 1

			return undefined
		},

		getScrollSpeedX(relativeX, scrollBuffer, contWidth){
			
			// Scroll up container (if slot in scroll up area)
			if (relativeX - this.width <= scrollBuffer)
				return Math.floor(Math.abs(relativeX - this.width) / this.scrollStep) * -1
			
			// Scroll down container (if slot in scroll down area)
			if (relativeX - contWidth + this.width >= scrollBuffer)
				return Math.floor(Math.abs(relativeX - contWidth + this.width) / this.scrollStep) + 1

			return undefined
		},

		scrollContainer(posY, posX){
			const relativeY = Math.round(posY - this.slotContainer.scrollTop)
			const relativeX = Math.round(posX + this.originColumnX - this.scrolledX)
			
			// console.log(`relative x : ${posX} + ${this.originColumnX} - ${this.scrolledX} = ${relativeX}`)

			const scrollBuffer = 0
			const contHeight = this.containerSizes["height"]
			const scrollAreaY = this.containerSizes["scroll-area-y"]

			/* 
				Interrupt the table scrolling if 
				1. The slot is in the non-border area
				2. The slot is in the top border area and the table is scrolled to the top
				3. The slot is in the bottom border area and the table is scrolled to the bottom
				4. The slot date is today and slot position top is less than the red (current time) line
			*/  
			
			this.isScrollBorderAreaX = relativeX - this.width <= scrollBuffer ||
										relativeX - this.containerSizes["width"] + this.width >= scrollBuffer

			this.isScrollBorderAreaY = relativeY - this.cellHeight <= scrollBuffer ||
										relativeY - contHeight + this.cellHeight >= scrollBuffer

			// Scroll function that shows the container reached a limit in Y axis
			
			const scrollConditionY = (target) => {

				const currentRelativeY = Math.round(this.posY - this.slotContainer.scrollTop)

				const isTopBorderArea = currentRelativeY - this.cellHeight <= scrollBuffer
				const isBotBorderArea = currentRelativeY - contHeight + this.cellHeight >= scrollBuffer 

				if (isTopBorderArea && this.slotContainer.scrollTop != 0) {
					return target.scrollTop > 0
				} else if (isBotBorderArea) {
					return Math.floor(scrollAreaY - contHeight - target.scrollTop - this.height - 50) > 0
				}
				return false
			}

			// Scroll function that shows the container reached a limit in X axis

			const scrollConditionX = (target) => {
				const currentRelativeX = Math.round(this.posX + this.originColumnX - this.scrolledX)

				console.log('relX', currentRelativeX)
				console.log('scrollLeft', target.scrollLeft)

				// const isLeftBorderArea = currentRelativeX - this.width <= scrollBuffer && target.scrollLeft > (this.width + 1) * 3 + 10
				const isLeftBorderArea = currentRelativeX - this.width <= scrollBuffer
				const isRightBorderArea = currentRelativeX - this.containerSizes["width"] + this.width >= scrollBuffer

				// console.log('scroll x cond :>> ', {x : currentRelativeX, left: isLeftBorderArea, right: isRightBorderArea});

				return isLeftBorderArea || isRightBorderArea
			}
			
			if (!this.isScrollBorderAreaX && !this.isScrollBorderAreaY){
				if (this.scrollInterval){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeedY = null
				this.scrollSpeedX = null
				return
			}


			// Update scroll speed for y axis

			if (!this.isScrollBorderAreaY) { 
				if (this.scrollInterval && !this.scrollSpeedX){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeedY = null
			} else {
				this.scrollSpeedY = this.getScrollSpeedY(relativeY, scrollBuffer, contHeight)
			}

			// Update scroll speed for x axis

			if (!this.isScrollBorderAreaX) {
				if (this.scrollInterval && !this.scrollSpeedY){
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
				}
				this.scrollSpeedX = null
			} else {
				this.scrollSpeedX = this.getScrollSpeedX(relativeX, scrollBuffer, this.containerSizes["width"])
				// console.log("speed x: ", this.scrollSpeedX)
				if (Math.abs(this.scrollSpeedX) > 10) {
					// console.log('object :>> ', {
					// 	relativeX, width: this.containerSizes["width"]
					// });
				} 
			}


			if (this.scrollInterval)
				return
			

			// Start scrolling using interval
			this.scrollInterval = scrollByCondition(this.slotContainer, {
				delay: 15,
				onScroll: this.onScrollContainerHandler,
				scrollConditionX: scrollConditionX,
				scrollConditionY: scrollConditionY,
				// scrollConditionX: () => true,
				getSpeedX: () => this.scrollSpeedX,
				getSpeedY: () => this.scrollSpeedY,
				onFinish: () => {
					clearInterval(this.scrollInterval)
					this.scrollInterval = null
					this.scrollSpeedX = null
					this.scrollSpeedY = null
				}
			})
		},	
		
		onScrollContainerHandler(){

			if (this.scrollSpeedX && this.isScrollBorderAreaX){
				const futureX = this.posX + this.scrollSpeedX
				this.scrolledX += this.scrollSpeedX
				this.posX = futureX
				this.futureColumn = this.calcFutureColumn(this.posX)
			}
			if (this.scrollSpeedY && this.isScrollBorderAreaY){
				const futureY = this.posY + this.scrollSpeedY
				this.posY = Math.max(0, Math.min(futureY, this.containerSizes["scroll-area-y"] - this.height - 10))
			}

			if (this.isScrollBorderAreaX || this.isScrollBorderAreaY) {
				eventBroker.$emit('scrollable-table', 'exec-scroll')
			}

			// console.log('final x :>> ', this.posX);

			this.sendMoveEvent(this.posX, this.posY)
		},

		pointerUpHandler(){

			this.isTouched = false
			this.dragging = null
			this.dragLimits = null

			this.scrollSpeedX = null
			this.scrolledX = 0
			this.originColumnX = 0
			this.scrollSpeedY = null
			this.lastMoveEvent = null
			
			eventBroker.$emit('scrollable-table', 'exec-scroll-end')
			eventBroker.$emit('scrollable-table', 'disable-slot-move-scroll')
			
			if (this.scrollInterval){
				clearInterval(this.scrollInterval)
				this.scrollInterval = null
			}

			const supportsPointerEvents = 'PointerEvent' in window;
			
			if (this.dragging && supportsPointerEvents) {
				this.$el.removeEventListener('pointermove', this.pointerMoveHandler);
			} else if (this.dragging) {
				this.$el.removeEventListener('mousemove', this.pointerMoveHandler);
			}
			
			const lastDragDate = new Date(this.futureColumn)
			lastDragDate.setHours(this.currentHour, this.currentMinute)

			// If future datetime is wrong -> return to the origin position
			if (!(this.timeIsWrong || this.dateIsWrong)) {
				this.posY = calcSlotCardPosY(lastDragDate, this.workTime, this.cellHeight)
			}


			if (isEqualDate(this.futureColumn, this.eventSlot.period.start.date))
				this.posX = 0
			
			this.$emit('on-move-end', {
				futureColumnDate: this.futureColumn,
			})
			// If future column is defined, then change the column
			if (this.futureColumn) {
				this.colorizeFutureColumn(null)
				this.futureColumn = null
			}

			
			makeUndraggable(this.slotCard, {
				move: this.pointerMoveHandler,
				end: this.pointerUpHandler,
			})
		},

		calcFutureColumn(posX){
			if (!this.columnsX || this.columnsX.length == 1)
				return this.eventSlot.period.start.date

			const columnCountLeft = this.columnsX
				.map((value) => value < 0 ? 1 : 0)
				.reduce((acc, value) => acc + value, 0) 
			const closestColumn = this.columnsX.map((columnX, index) => {
				return {
					index: index - columnCountLeft,
					diff: Math.abs(columnX - posX)
				}
			}).sort((a, b) => a.diff - b.diff)[0]

			// console.log('closest column :>> ', closestColumn, this.columnsX);

			const closestColumnDate = new Date(this.eventSlot.period.start.date)
			closestColumnDate.setDate(closestColumnDate.getDate() + closestColumn.index)

			this.colorizeFutureColumn(closestColumnDate)
			return closestColumnDate
		},

		colorizeFutureColumn(futureColumn){
			const columnTitles = Array.from(this.slotContainer.querySelectorAll('.calendar-date-title__item'))
			if (!futureColumn) {
				columnTitles.forEach((titleElem) => {
					titleElem.classList.remove('selected')
				})
				return
			}

			columnTitles.forEach((titleElem) => {
				titleElem.classList.remove('selected')
			})

			const columnDate = columnTitles.find((titleElem) => {
				const id = futureColumn.toLocaleDateString()
				return titleElem.id == `calendar-date-title__item-${id}`
			})
			if (!columnDate)
				return
			columnDate.classList.add('selected')
		},
		moveTo(posX, posY){
			if (posX == -1 || posY == -1) {
				this.posX = 0
				this.posY = this.originY
				return
			}

			this.posX = posX
			this.posY = posY
		}
	},
};
</script>

<style scoped>
.slot-card__move-behavior{
	position: absolute;
	height: fit-content;
	left: 0;
	width: 100%;
}

.slot-card__move-behavior.is-dragging{
	outline: 1.5px solid #0075ff;
	border-radius: 3px;
}

.slot-card__move-behavior.is-dragging.is-invalid{
	outline: 1.5px solid #f14668;
}
</style>