import CalendarWeek from "./calendarWeek"
import { dateRange, isEqualDate, nextDate, weekStart } from "./date"

class CalendarMonth {
	firstDate
	from
	to

	$cacheRange=null
	$cacheWeeks=null

	/**
	 * Make month instance for using in calendar (basically contain 5 week range)
	 * @param {Date} date - date of month 
	 */
	constructor(date) {
		this.firstDate = new Date(date)
		this.firstDate.setDate(1)
		this.firstDate.setHours(0, 0, 0, 0)

		this.from = weekStart(this.firstDate)
		this.to = nextDate(this.from, 34)
	}

	range(){
		if (!this.$cacheRange)
			this.$cacheRange = dateRange(this.from, this.to)
		return this.$cacheRange
	}

	findWeek(weekNumber){
		const weeks = this.getWeeks()
		return weeks.find(week => week.number == weekNumber)
	}

	getWeeks(){

		if (this.$cacheWeeks)
			return this.$cacheWeeks

		let iter = new Date(this.from)
		const weeks = []
		let counter = 0
		while (counter < 5) {
			weeks.push(new CalendarWeek(iter, nextDate(iter, 6)))
			iter = nextDate(iter, 7)
			counter++;
		}

		this.$cacheWeeks = weeks
		return weeks
	}

	/**
	 * Check if date is in month
	 * @param {Date} date - date to check if it's in month
	 * @returns boolean 
	 */
	includes(date) {
		return this.firstDate.getMonth() === date.getMonth()
			&& this.firstDate.getFullYear() === date.getFullYear()
	}
}

export default CalendarMonth