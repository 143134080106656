<template>
	<div class="calendar-v2" 
	ref="calendar">
		<!-- <div class="support-message" v-if="supportMessage">
			{{ supportMessage }}
		</div>
		 -->
		<ScrollableTable ref="table"
		:loadedDates="loadedDates" 
		:cellHeight="cellHeight"
		:workTime="workTime"
		:slotCollection="slotCollection"
		:slotContainer="slotContainer"
		:isEditable="isEditable"
		:isShowDateTitles="isShowDateTitles"
		@on-select-date="onSelectDate"
		@on-scroll-x="({deltaX, deltaDates}) => $emit('on-scroll-x', {deltaX, deltaDates})"
		@on-scroll-start="(ev) => $emit('on-scroll-start', ev)"
		@on-scroll-move="(ev) => $emit('on-scroll-move', ev)"
		@on-scroll-end="(ev) => $emit('on-scroll-end', ev)">

		</ScrollableTable>
	</div>
		<!-- Past time backdrop -->
	<!-- <PastTimeBackdrop
		:tableHeader="tableHeader"
		:workTime="workTime"
		:container="$el"
		:columnCount="columnCount"
	/> -->


</template>

<script>
import { dateIsPast, isEqualDate, pair } from '../../scripts/date';

import CalendarWorkTime from '../../scripts/calendarWorkTime';
import Column from './Column.vue';
import CalendarHeader from '../../scripts/calendarHeader';
import SlotCollection from '../../scripts/slotCollection';
import { getColumnWidth } from '../../scripts/column';

import Hours from './Hours.vue';
import CalendarDateTitle from './CalendarDateTitle.vue';
import PastTimeBackdrop from './PastTimeBackdrop.vue';
import ScrollableTable from './ScrollableTable.vue';
import TimePeriod from '../../scripts/timePeriod';
import CalendarLoadedDates from '../../scripts/calendarLoadedDates';

export default {
	name: 'CalendarV2',
	emits: [
		'on-select-date',
		'on-scroll-x',
		'on-scroll-start',
		'on-scroll-move',
		'on-scroll-end',
	],

	components: {
		PastTimeBackdrop,
		ScrollableTable
	},

	props: {
		loadedDates: {
			type: CalendarLoadedDates,
			required: true,
		},

		calendarUid: {
			type: String,
			required: true,
		},

		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		slotCollection : {
			type: SlotCollection,
			required: true,
		},

		cellHeight: {
			type: Number,
		},

		isSyncScroll: {
			type: Boolean,
			default: false,
		},
		isShowDateTitles: {
			type: Boolean,
			default: true,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		defaultColumnWidth() {
			return getColumnWidth(this.loadedDates.selected.daysCount, false, 45) + 'px';
		},
		selectedColumnWidth() {
			return getColumnWidth(this.loadedDates.selected.daysCount, true, 45) + 'px';
		},
		supportMessage(){
			const rangeStart = this.loadedDates.selected.start.date
			const rangeEnd = this.loadedDates.selected.end.date

			if (dateIsPast(rangeStart) && dateIsPast(rangeEnd)) {
				return 'Archive mode';
			}
			return null
		},
		columnViewType(){
			const columnCount = this.loadedDates.selected.daysCount
			if (columnCount == 1)
				return "full"
			else if (columnCount == 3 || columnCount == 5)
				return "middle"
			else if (columnCount == 7)
				return "short"
			return "full"
		},
		columnWidthPxComp(){
			this.loadedDates.selected
			this.isCountColumnWidth

			this.$nextTick(() => {
				this.columnWidthPx = this.calcColumnWidth()
			})

			return undefined
		},
	},

	mounted() {
		this.slotContainer = this.$refs['table'].$el
		this.isMounted = true
		this.$nextTick(() => {
			this.columnWidthPx = this.calcColumnWidth()
		})
	},

	data() {
		return {
			slotContainer: null,
			scrollTimer: null,
			columnsCollections: [],
			tableScrollX: 0,
			isMounted: false,
			isCountColumnWidth: false,

			columnWidthPx: 0,
			cachedTableHeader: null,
		};
	},

	beforeDestroy() {
		this.slotContainer = null
	},

	methods: {
		pair: pair,
		syncScroll(){

			let currentCalendar = this.$el
			if (!currentCalendar)
				return
			let currentCalendarTable = this.$el.querySelector('.calendar-v2-table')
			if (!currentCalendarTable)
				return

			let anotherCalendar = Array.from(document.querySelectorAll(".calendar-v2-table"))
				.find(calendar => calendar != this.$el)
			if (!anotherCalendar)
				return

			currentCalendarTable.scrollTo({top: anotherCalendar.scrollTop, left: 0}) 
		},
		calcColumnWidth(){
			const elem = this.$el
			const column = elem.querySelector('.calendar-column')
			if (!column) {
				return 0
			}
 			const width = column.offsetWidth

			return Math.round(width)
		},
		onSelectDate(date) {
			this.$emit('on-select-date', date)
		},
	},
};
</script>

<style scoped>

.calendar-v2 {
	position: relative;
	width:  100%;
	height: 100%;
}

.support-message{
	position: fixed;
	top: 25px;
	width: 100%;
	z-index: 4;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
}


</style>