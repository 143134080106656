<template>
	<div class="calendar-date-title" ref="title">
		<div class="calendar-date-title__item"
		v-for="date in dates"
		:id="`calendar-date-title__item-${date.toLocaleDateString()}`"
		:key="`calendar-date-title__item-${date.toLocaleDateString()}`"
		v-bind:style="{
			'min-width' : columnWidth,
		}">
			{{ calcTitle(date) }}
		</div>
	</div>
</template>

<script>
import { enShortMonths } from '../../scripts/months';

export default {
	name: 'CalendarDateTitle',

	props: {
		dates: {
			type: Array,
			required: true,
		},
		columnWidth: {
			type: String,	
			required: true,
		},
	},

	methods: {

		calcTitle(date){
			let dateNumber = date.getDate()
			let monthName = enShortMonths[date.getMonth()]
			return `${dateNumber} ${monthName}`
		},
	}
};
</script>

<style scoped>
.calendar-date-title {
	position: sticky;
	top: 0;
	z-index: 1;

	width: fit-content;
	display: flex;
	display: flex;
	flex-direction: row;
	width: 100%;
}

.calendar-date-title__item{
	transition: all 0.3s;
	font-size: 11px;
	color: grey;
	display: flex;
	flex: 1;
	justify-content: center;
}

.calendar-date-title__item.selected{
	color: #0075ff;
	font-size: 12px;
	font-weight: 500;
}
</style>