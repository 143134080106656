class ScrollOptions {
	constructor(onScroll, delay, scrollConditionX, scrollConditionY, getSpeedX, getSpeedY) {
		this.onScroll = onScroll
		this.delay = delay
		this.scrollConditionX = scrollConditionX
		this.scrollConditionY = scrollConditionY
		this.getSpeedX = getSpeedX
		this.getSpeedY = getSpeedY
	}
}

/**
 * Make scroll of the target function while scrollCondition function return true 
 * @param {HTMLElement} target Target HTML element to scroll 
 * @param {ScrollOptions} opts Contain additional options for the scroll:
 * - delay - delay between executions of the scroll in milliseconds (default 10)
 * - onScroll - function that execute after each one small scroll (default null) 
 * @returns Interval instance
 */
function scrollByCondition(target, opts={
	onScroll: null, 
	delay: 10,
	scrollConditionX: () => false,
	scrollConditionY: () => false,
	getSpeedX: () => 2,
	getSpeedY: () => 2,
	onFinish: null,
}) {
	let iterScrollTop = target.scrollTop
	let iterScrollLeft = target.scrollLeft
	const finishHandler = opts.onFinish == null ?
		(scrollInterval) => clearInterval(scrollInterval) : opts.onFinish

	const scrollInterval = setInterval(() => {


		const speedX = opts.getSpeedX ? 
			opts.getSpeedX() : undefined
		const speedY = opts.getSpeedY ?
			opts.getSpeedY() : undefined

		const isScrollX = opts.scrollConditionX && opts.scrollConditionX(target)
		const isScrollY = opts.scrollConditionY && opts.scrollConditionY(target)

		if (!isScrollX && !isScrollY) {
			return finishHandler(scrollInterval)
		}

		if (isScrollX && speedX) {
			iterScrollLeft += speedX
		}
		if (isScrollY && speedY) {
			iterScrollTop += speedY
		}

		// Not working in this way with Safari/IOS 
		// target.scrollLeft = iterScrollLeft
		// target.scrollTop = iterScrollTop
		target.scrollTo(iterScrollLeft, iterScrollTop)

		if (opts.onScroll) {
			opts.onScroll(target)
		}
	}, opts.delay)
	return scrollInterval
}

export {
	scrollByCondition
}