<template>
	<div class="calendar-v2-table" ref="table" >

		<div class="holder"></div>

		<CalendarDateTitle v-if="isShowDateTitles"
			:dates="visibleDates"
			:columnWidth="columnWidthRaw"
		/> 
		<Hours ref="hours"
			:workTime="workTime"
			:cellHeight="cellHeight"
		/>

		<div class="calendar-v2-table__column-section" ref="columns">
			<Column
				v-for="date in visibleDates"
				:key="`column-date-${date.toLocaleDateString()}`"
				:date="date"
				:cellHeight="cellHeight"
				:slotCollection="slotCollection"
				:slotContainer="slotContainer"
				:workTime="workTime.projection(date)"
				:columnCount="loadedDates.selected.daysCount"
				:isEditable="isEditable"
				:width="columnWidthRaw"
			/>
		</div>
	</div>

</template>

<script>
import CalendarLoadedDates from '../../scripts/calendarLoadedDates';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import { dateRange, isEqualDate, nextDate } from '../../scripts/date';
import { makeDraggable, makeUndraggable } from '../../scripts/draggable'
import SlotCollection from '../../scripts/slotCollection';
import CalendarDateTitle from './CalendarDateTitle.vue';
import Column from './Column.vue';
import eventBroker from './eventBus';
import Hours from './Hours.vue';

export default {
	name: 'ScrollableTable',

	components: {
		Column,
		Hours,
		CalendarDateTitle,
	},

	emits: [
		'on-select-date',
		'on-scroll-x',
		'on-scroll-start',
		'on-scroll-move',
		'on-scroll-end',
	],

	props: {
		loadedDates: {
			type: CalendarLoadedDates,
			required: true,
		},
		cellHeight: {
			type: Number,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		slotCollection: {
			type: SlotCollection,
			required: true,
		},
		slotContainer: {
			type: HTMLElement,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		isShowDateTitles: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		tableElement() {
			return this.$refs['table']
		},
		hoursWidth(){
			if (!this.isMounted){
				return 0
			}

			const hoursElem = this.$refs['hours']
			if (!hoursElem) {
				return 0
			}
			return hoursElem.$el.offsetWidth
		},
		columnWidthRaw(){
			if (!this.isMounted){
				return 'none'
			}
			return `${100 / this.loadedDates.selected.daysCount}%`
		},
	},

	data() {
		return {
			originX: 0,
			lastX: 0,
			selectedIndex: 0,
			isTouched: false,
			isSlotMoveScroll: false,
			tableOverflowCallback: null,
			columnWidthPx: 0,
			isMounted: false,
			visibleDates: [],
			oldSelected: null,
			scrollLimits: {
				left: 0,
				right: 0,
			}
		};
	},

	watch: {
		columnWidthPx(){
			this.roundScrollX(false)
		},
		'loadedDates.selected': {
			handler(newSelected, oldSelected){
				
				const oldStart = oldSelected.start.date
				const oldRange = oldSelected.daysCount
				const newStart = newSelected.start.date
				const newRange = newSelected.daysCount
				const isWithAnimation = !isEqualDate(oldStart, newStart) && oldRange == newRange

				this.$nextTick(() => {									
					if (oldRange != newRange) {
						this.columnWidthPx = this.calcColumnWidth()
					}
					if (!isEqualDate(oldStart, newStart)) {
						this.setupSelectedIndex()
					}
					
					this.roundScrollX(isWithAnimation)
				})
			},
			deep: true,
		}
	},	

	created(){
		this.visibleDates = this.loadedDates.visibleDateRange()
	},

	mounted() {
		makeDraggable(this.tableElement, {
			start: this.onTouchStart,
			move: this.onTouchMove,
			end: this.onTouchEnd,
		})
		this.setupColumnWidth()
		this.tableElement.addEventListener('scrollend', this.onScrollEnd)
		this.tableElement.addEventListener('scroll', this.onScroll)
		this.selectedIndex = this.calcSelectedIndex()
		this.isMounted = true
		eventBroker.$on('scrollable-table', 'enable-slot-move-scroll', (overflowCallback) => {
			this.isSlotMoveScroll = true
			this.tableOverflowCallback = overflowCallback
			eventBroker.$on('scrollable-table', 'exec-scroll', this.onScroll)
			eventBroker.$on('scrollable-table', 'exec-scroll-end', this.onScrollEnd)
			this.tableElement.removeEventListener('scrollend', this.onScrollEnd)
			this.tableElement.removeEventListener('scroll', this.onScroll)
		})
		
		eventBroker.$on('scrollable-table', 'disable-slot-move-scroll', () => {
			this.isSlotMoveScroll = false
			this.tableOverflowCallback = null
			eventBroker.$off('scrollable-table', 'exec-scroll')
			eventBroker.$off('scrollable-table', 'exec-scroll-end')
			this.tableElement.addEventListener('scrollend', this.onScrollEnd)
			this.tableElement.addEventListener('scroll', this.onScroll)
		})
	},

	beforeDestroy() {
		makeUndraggable(this.tableElement, {
			start: this.onTouchStart,
			move: this.onTouchMove,
			end: this.onTouchEnd,
		})
		this.tableElement.removeEventListener('scrollend', this.onScrollEnd)
		this.tableElement.removeEventListener('scroll', this.onScroll)
		eventBroker.$off('scrollable-table')
	},

	methods: {
		onTouchStart(ev) {
			const table = this.$refs['table']
			if (!table) {
				ev.preventDefault();
				return
			}
			this.isTouched = true
			this.originX = table.scrollLeft
			this.lastX = table.scrollLeft
			this.scrollLimits = {
				left: 3 * this.columnWidthPx,
				right: table.scrollWidth - table.clientWidth - 3 * this.columnWidthPx,
			}
			this.$emit('on-scroll-start', ev)
		},
		
		onTouchMove(ev) {

			this.$emit('on-scroll-move', ev)
		},

		onTouchEnd(ev){


		},
		
		onScrollEnd(){
			if (!this.isTouched)
				return
			this.isTouched = false
			const newScrollX = this.$refs['table'].scrollLeft
			const deltaX = Math.round((newScrollX - this.originX) / this.columnWidthPx)
			if (deltaX != 0) {
				const newDate = new Date(this.loadedDates.selected.start.date)
				newDate.setDate(newDate.getDate() + deltaX)
				this.$emit('on-select-date', newDate)
				const weekIndex = this.loadedDates.weekIndex(newDate)
				if (weekIndex != this.loadedDates.selectedWeekIndex) {
					this.loadedDates.updateWeekIndex(weekIndex)
					const monthIndex = this.loadedDates.monthIndex(newDate)
					if (monthIndex != this.loadedDates.selectedMonthIndex) {
						this.loadedDates.updateMonthIndex(monthIndex)
					}
				}
				
			} else {
				this.roundScrollX()
			}
			this.lastX = newScrollX
			this.originX = newScrollX
			this.$emit('on-scroll-end')
		},

		onScroll(){
			const newScrollX = this.$refs['table'].scrollLeft
			if (this.isTouched && Math.abs(newScrollX - this.lastX) > 0.5) {
				const deltaX = Math.round(newScrollX - this.originX)
				const deltaDates = deltaX / this.columnWidthPx
				this.$emit('on-scroll-x', {deltaX, deltaDates})
				this.onScrollX(deltaX)
				this.lastX = newScrollX
				return
			}
		},

		onScrollX(deltaX){
			const table = this.$refs['table']
			const tableScrollX = table.scrollLeft

			if (tableScrollX >= this.scrollLimits["right"]) {
				this.loadedDates.pushVisibleDates()
				this.scrollLimits["right"] += 15 * this.columnWidthPx
				if (!this.tableOverflowCallback) {
					return
				}
				this.tableOverflowCallback("right", 15)
			} else if (tableScrollX < this.scrollLimits["left"]) {
				const oldScrollLeft = table.scrollWidth - table.scrollLeft
				this.loadedDates.unshiftVisibleDates()
				this.$nextTick(() => {
					table.scrollLeft = table.scrollWidth - oldScrollLeft;
					this.originX += 15 * this.columnWidthPx
					if (!this.tableOverflowCallback) {
						return
					}
					this.tableOverflowCallback("left", 15)
				});
			}
		},

		roundScrollX(smooth=true){
			if (this.selectedIndex == -1 || this.isTouched || this.columnWidthPx < 10)
				return

			const columns = Array.from(this.$refs['columns'].querySelectorAll('.calendar-column'))
			const targetColumn = columns[this.selectedIndex]
			
			this.$nextTick(() => {
				if (this.isTouched) {
					return
				}
				const targetColumnLeft = targetColumn.getBoundingClientRect().left
				if (targetColumnLeft) {
					this.$refs['table'].scrollTo({
						left: this.$refs['table'].scrollLeft + targetColumnLeft - this.hoursWidth,
						behavior: smooth ? 'smooth' : 'auto',
					})
				} else {
					this.$refs['table'].scrollTo({
						left: this.selectedIndex * this.columnWidthPx,
						behavior: smooth ? 'smooth' : 'auto',
					})
				}
			})
		},

		calcSelectedIndex(){
			const rangeStart = this.loadedDates.selected.start.date
			const rangeStartIndex = this.visibleDates.findIndex(date => isEqualDate(date, rangeStart))
			if (rangeStartIndex != -1) {
				return rangeStartIndex
			}
			const withRefresh = true
			this.visibleDates = this.loadedDates.visibleDateRange(withRefresh)
			return this.visibleDates.findIndex(date => isEqualDate(date, rangeStart))
		},

		setupSelectedIndex(){
			this.selectedIndex = this.calcSelectedIndex()
			if(this.selectedIndex != -1) {
				return
			}
			let tryCount = 0
			const interval = setInterval(() => {
				this.selectedIndex = this.calcSelectedIndex()

				if (this.selectedIndex != -1) {
					clearInterval(interval)
				}
				if (tryCount >= 3) {
					clearInterval(interval)
				}
				tryCount++
			}, 50)
		},

		setupColumnWidth(){
			let columnWidth = this.calcColumnWidth()
			if (columnWidth != 0) {
				this.columnWidthPx = columnWidth
				return 
			}

			let tryCount = 0
			const interval = setInterval(() => {
				let columnWidth = this.calcColumnWidth()
				if (columnWidth != 0) {
					clearInterval(interval)
					this.columnWidthPx = columnWidth
				}
				if (tryCount >= 3) {
					clearInterval(interval)
				}
				tryCount++
			}, 50)
		},

		calcColumnWidth(){
			if (!this.$refs['columns'] || this.selectedIndex == -1)
				return 0

			const columns = Array.from(this.$refs['columns'].querySelectorAll('.calendar-column'))
			const targetColumn = columns[this.selectedIndex]
			return targetColumn.getBoundingClientRect().width
		},
	},
	
};
</script>

<style scoped>

.calendar-v2-table__column-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.holder{
	background:white;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 5;
	width: 32px;
	height: 20px;
}

.calendar-v2-table {
	
	overflow: auto;
	position: relative;
	/* padding-bottom: 50px; */
	width:  100%;
	height: 100%;

	display: grid;
	grid-template-columns: 32px calc(100% - 32px);
	grid-template-rows: 20px calc(100% - 20px);
}
.calendar-v2.no-scroll {
  	overflow: hidden;
}

</style>