import CalendarMonth from "./calendarMonth"
import { isEqualDate, smartDateSelect, weekNumber, dateRange, nextDate, dateDifferenceInDays } from "./date"
import SelectedDateRange from "./selectedDateRange"

const DATE_PADDING = 15

class CalendarLoadedDates {

    selected = null

    selectedWeekIndex = null  // Index for the weekly calendar
    selectedMonthIndex = null // Index for the monthly calendar  
    
    months = null
	$cacheDateRange=null
    $cacheWeekRange=null
    $cacheVisibleDateRange=null
    from = null
    to = null

    constructor(selectedDate, daysCount) {
        this.selected = new SelectedDateRange(selectedDate, daysCount) 
        this.setupMonths(selectedDate)
    }

    /**
     * Setup months array for calendar
     * @param {Date} centralMonthDate - date of the month that will be in bordered by rangeBefore and rangeAfter months
     * @param {Number} rangeBefore - amount of months before centralMonthDate
     * @param {Number} rangeAfter - amount of months after centralMonthDate
     */
    setupMonths(centralMonthDate, rangeBefore=6, rangeAfter=6) {
        const startDate = new Date(centralMonthDate)
        startDate.setDate(1)
        startDate.setHours(0, 0, 0, 0)
        
        let counter = 0
        while (counter < rangeBefore) {
            startDate.setMonth(startDate.getMonth() - 1, 1)
            counter++
        }

        const months = []
        while (months.length < rangeBefore + rangeAfter + 1) {
            months.push(new CalendarMonth(startDate))
            startDate.setMonth(startDate.getMonth() + 1, 1)
        }
        
        this.months = months
        this.$cacheDateRange = null
        this.from = this.months[0].from
        this.to = this.months[this.months.length - 1].to
        this.selectedMonthIndex = this.monthIndex(centralMonthDate) 
        this.setupWeeks(centralMonthDate)
    }
    
    setupWeeks(weekDate){
        const weeks = []

        this.months.forEach(month => {
            month.getWeeks().forEach(week => {
                if (week && (weeks.length == 0 || weeks[weeks.length - 1].number != week.number)) {
                    weeks.push(week)
                }
            })
        })

        this.weeks = weeks
        this.selectedWeekIndex = this.weekIndex(weekDate)
    }

    /**
     * Make the date range of loaded dates
     * @returns {Array<Date>}
     */
    dateRange(){
		if (!this.$cacheDateRange)
			this.$cacheDateRange = dateRange(this.from, this.to)
		return this.$cacheDateRange
	}

    visibleDateRange(isRefresh=false){

        if (!this.$cacheVisibleDateRange || isRefresh)
            this.$cacheVisibleDateRange = this.$calcVisibleDates()
        return this.$cacheVisibleDateRange
    }

    /**
	 * Check if date is in loaded dates
	 * @param {Date} date - date to check 
	 * @returns boolean 
	 */
	includes(date) {
        const isFrom = isEqualDate(this.from, date) 
        const isTo = isEqualDate(this.to, date)
        const isInsideRange = date >= this.from && date <= this.to
        return isFrom || isTo || isInsideRange
	}

    isFirstMonth(monthIndex) {
        return monthIndex === 0
    }

    isLastMonth(monthIndex) {
        return monthIndex === this.months.length - 1
    }

    monthIndex(date) {
        return this.months.findIndex(month => month.includes(date))
    }

    weekIndex(date) {
        return this.weeks.findIndex(week => week.includes(date))
    }

    select(selectedDate, isWithSmartSelect=true) {
        if (isEqualDate(selectedDate, this.selected.startDate))
            return

        const smartDate = isWithSmartSelect ? 
            smartDateSelect(selectedDate, this.selected.daysCount) : selectedDate
 
        this.selected = new SelectedDateRange(smartDate, this.selected.daysCount)
        if (!this.includes(this.selected.start.date) || !this.includes(this.selected.end.date)) {
            this.setupMonths(selectedDate)
            return
        }
    }

    resize(daysCount) {
        if (daysCount == this.selected.daysCount)
            return

        const smartDate = smartDateSelect(this.selected.start.date, daysCount)

        this.selected = new SelectedDateRange(smartDate, daysCount)
        if (!this.includes(this.selected.start.date) || !this.includes(this.selected.end.date)) {
            this.setupMonths(this.selected.start.date)
            return
        }
    }

    updateWeekIndex(weekIndex) {
        this.selectedWeekIndex = weekIndex
    }

    updateMonthIndex(monthIndex) {
        this.selectedMonthIndex = monthIndex
    }

    $calcVisibleDates() {
        const selectedRangeStart = this.selected.start.date
        const selectedRangeEnd = this.selected.end.date


        const visibleStart = nextDate(selectedRangeStart, -DATE_PADDING)
        const visibleEnd = nextDate(selectedRangeEnd, DATE_PADDING) 

        return dateRange(visibleStart, visibleEnd)
    }

    pushVisibleDates(){
        if (!this.$cacheVisibleDateRange || !this.$cacheVisibleDateRange.length) {
            return 
        }
        const chunkStart = nextDate(this.$cacheVisibleDateRange[this.$cacheVisibleDateRange.length - 1], 1)
        const chunkEnd = nextDate(chunkStart, DATE_PADDING - 1)

        const chunk = dateRange(chunkStart, chunkEnd)
        this.$cacheVisibleDateRange.push(...chunk)
    }

    unshiftVisibleDates(){
        if (!this.$cacheVisibleDateRange || !this.$cacheVisibleDateRange.length) {
            return 
        }
        const chunkEnd = nextDate(this.$cacheVisibleDateRange[0], -1)
        const chunkStart = nextDate(chunkEnd, -(DATE_PADDING - 1))
        
        const chunk = dateRange(chunkStart, chunkEnd)
        this.$cacheVisibleDateRange.unshift(...chunk)	
    }
}

export default CalendarLoadedDates