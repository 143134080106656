import { dateRange, isEqualDate, isEqualTime } from "./date"

/**
 * Sorting fucn for the period
 * @param {TimePeriod} periodA - first period
 * @param {TimePeriod} periodB - second period
 * @returns time difference between period start date
 */
function sortPeriodsByTime(periodA, periodB) {
	return periodA.start.time - periodB.start.time
}


const DURATION = 60 // 1 hour 
/**
 * Time period for the calendar cards
 */
class TimePeriod {

    #cachedDateRange = null

    /**
     * Default: 1 hour period
     * @param {Date} start - start of the time period
     * @param {Date} end - end of the time period
     * @param {Number} index - index of card
     * @param {Number} duration - time duration betwen start and end in minutes.
     */
    constructor(start, index, duration = DURATION, end) {

        if (!start) start = new Date()
        start.setSeconds(0)

        let localEnd = end
        if (!localEnd) {
            localEnd = new Date(start.getTime())
            localEnd.setMinutes(localEnd.getMinutes() + duration)
        }


        this.start = { date: start, time: start.getTime() }
        this.end = { date: localEnd, time: localEnd.getTime() }
        this.index = index || 'undefined'

        if (!end) {
            this.duration = duration
        } else {
            this.duration = (end.getTime() - start.getTime()) / 1000 / 60
        }

    }

    minute = () => this.start.date.getMinutes()
    hour = () => this.start.date.getHours()
    date = () => this.start.date.getDate()
    month = () => this.start.date.getMonth()
    year = () => this.start.date.getFullYear()

    /**
     * Checks whether a date belongs to a time period
     * @param {Date} date - date
     * @param {Boolean} strictly - is strict comparison
     */
    include(date, strictly = true) {
        let timestamp = date.getTime()
        return strictly ?
            this.start.time < timestamp && this.end.time > timestamp :
            this.start.time <= timestamp && this.end.time >= timestamp
    }

    /**
     * Checks for the intersection of two time periods
     * @param {TimePeriod} timePeriod - second time period
     * @param {Boolean} strictly - is strict comparison
     */
    intersection(timePeriod, strictly = true) {
        return this.include(timePeriod.start.date, strictly) 
			|| this.include(timePeriod.end.date, strictly)
    }

    /**
     * Return projection of time period on this date
     * @param {Date} date - date on which the projection will be made 
     */
    projection(date) {
        let period = this.copy()
        let projectionDate = new Date(date.getTime())

        projectionDate.setHours(period.hour(), period.minute())

        if (period.hour() == 0 && period.minute() == 0) {
            projectionDate.setDate(date.getDate() + 1)
        }

        period.update(projectionDate)

        return period
    }

    /**
     * Update time period
     * @param {Date} start - start of the time period
     */
    update(start) {
        start.setSeconds(0)
        let end = new Date(start.getTime())
        end.setMinutes(end.getMinutes() + this.duration)
        this.start = { date: start, time: start.getTime() }
        this.end = { date: end, time: end.getTime() }
        this.#cachedDateRange = null
    }

    /**
     * Shift by minutes
     * @param {Number} step - number of minutes to shift
     */
    shift(step) {
        let date = new Date(this.start.date.getTime())
        date.setMinutes(date.getMinutes() + step)
        this.update(date)
    }

    /**
     * Create a copy of this time period
     */
    copy() {
        return new TimePeriod(this.start.date, this.index, this.duration)
    }

    /**
     * Check equals TimePeriod
     * @param {TimePeriod} timePeriod - another time period
     */
    isEqual(timePeriod) {
        return isEqualDate(this.start.date, timePeriod.start.date)
			&& isEqualTime(this.start.date, timePeriod.start.date)
			&& this.duration == timePeriod.duration
    }

    /**
     * Return string with main time period data 
     */
    toString() {
        return ` ${this.date()}.${this.month()} ${this.start.date.toLocaleTimeString()} - ${this.end.date.toLocaleTimeString()}`
    }

    /**
     * Return period is past 
     */
    isPast() {
        let now = new Date()
        return now.getTime() > this.end.time
    }

	updateDuration(duration) {
		this.duration = duration
		this.update(this.start.date)
	}	

	/**
	 * Split current time period by another one time period.
	 * Return array of time periods that inside current one
	 * @param {TimePeriod} timePeriod - time period that inside current one
	 */
	splitByPeriod(timePeriod) {
		if (!timePeriod)
			return undefined

		if (!this.include(timePeriod.start.date) 
		|| !this.include(timePeriod.end.date))
			return undefined

		const result = []
		
		result.push(new TimePeriod(this.start.date, -1, undefined, timePeriod.start.date))
		result.push(new TimePeriod(timePeriod.end.date, -1, undefined, this.end.date))

		return result
	}

    dateRange(){
        if (this.#cachedDateRange)
            return this.#cachedDateRange
        const roundStart = new Date(this.start.date.getTime())
        roundStart.setHours(0, 0, 0, 0)
        const roundEnd = new Date(this.end.date.getTime())
        roundEnd.setHours(0, 0, 0, 0)
        const result = dateRange(roundStart, roundEnd)
        this.#cachedDateRange = result
        return result
    }
}

export {
	sortPeriodsByTime
}

export default TimePeriod