import { nextDate } from "./date";
import TimePeriod from "./timePeriod";

class SelectedDateRange extends TimePeriod {
    
    daysCount = null

    constructor(selectedDate, daysCount) {
        super(selectedDate, -1, 0, nextDate(selectedDate, daysCount - 1));
        this.daysCount = daysCount
    }
}

export default SelectedDateRange