<template>
	<div id="schedule-table">
		
		<ScheduleTableFrame
			v-if="workTime"
			:calendar="calendar"
			:loadedDates="loadedDates"
			:editTableCellHeight="editTableCellHeight"
			:disableHeader="disableHeader"
			:isEditable="isEditable"
			@on-select-date="onSelectDate"
		/>
		
		<ScheduleTableDelete
			:show="slotMoveEvent && pageActivity == 'create-slot'"
			:isDeletePreparation="isDeletePreparation"
		/>

		<ScheduleCreateSlot v-if="slotMoveEvent 
				&& isEditable 
				&& (!pageActivity || pageActivity == 'create-slot')" 
			:moveEvent="slotMoveEvent"
			:workTime="workTime"
			:isGridView="isEditable"
			:calendar="calendar"
			:loadedDates="loadedDates"
			:isDeletePreparation="isDeletePreparation"
			@on-drag-start="setPageActivity('create-slot')"
			@on-drag-move="checkIntersectionWithBasket"
			@on-drag-end="submitSlot"
		/>
	</div>
</template>

<script>
import { cssVw } from '../../scripts/cssUnits';
import { dateIsPast, dateRange, isEqualDate, nextDate } from '../../scripts/date';
import ScheduleTableFrame from './ScheduleTableFrame.vue';
import ScheduleCreateSlot from './ScheduleCreateSlot.vue';
import BCarouselListMod from '../BCarouselListMod.vue';
import CalendarWorkTime from '../../scripts/calendarWorkTime';
import SlotCollection from '../../scripts/slotCollection';
import { scrollByCondition } from '../../scripts/scroll';
import ScheduleTableDelete from './ScheduleTableDelete.vue';
import Calendar from '../../scripts/calendar';
import Slot from '../../scripts/slot';
import eventBroker from '../calendar-v2/eventBus';
import CalendarMonth from '../../scripts/calendarMonth';
import TimePeriod from '../../scripts/timePeriod';
import CalendarLoadedDates from '../../scripts/calendarLoadedDates';
export default {
	name: "ScheduleTable",
	components: {
		ScheduleTableFrame,
		ScheduleCreateSlot,
		ScheduleTableDelete,
		BCarouselListMod,
	},
	emits: [
		'on-select-date',
	],
	props: {
		calendar: {
			type: Calendar,
			required: true,
		},
		loadedDates: {
			type: CalendarLoadedDates,
			required: true,
		},
		workTime: {
			type: CalendarWorkTime,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		disableHeader: {
			type: Boolean,
			default: false,
		},
	},
	data(){
		return {
			isSwipeActive: false,
			currentTimeIsSet: false,
			editTableCellHeight: 54,
			isDeletePreparation: false,
		}
	},
	watch: {


		workTime(newValue){
			if (!newValue || this.currentTimeIsSet)
				return
			if (this.workTime && this.slotCollection.isLoaded){
				setTimeout(this.setupCalendarScroll, 10)
			}
		}, 
		// daysCount(value, oldValue){

		// 	if (value == 1 && oldValue != 1 || value != 1 && oldValue == 1){
		// 		let activeTable = this.activeEditableTable()
		// 		if (activeTable) {
		// 			sessionStorage.setItem(`schedule-table-${this.calendarUid}-scroll`, activeTable.scrollTop)
		// 		}
		// 	}

		// 	const activeTable = this.activeEditableTable()
		// 	if (activeTable) {
		// 		this.$nextTick().then(this.setScrollWithoutTransition)
		// 	}
		// },
	},

	computed: {
		slotMoveEvent(){
			return this.$store.getters.tableCreateEvent
		},
		calendarUid(){
			return this.$route.params.calendarUid
		},
		pageActivity(){
			return this.$store.getters.pageActivity
		},
		slotCollection(){
			return this.$store.getters.calendarSlotCollection
		}

	},
	mounted() {
		this.setupScroll()
	},

	methods: {
		setupScroll(){
			if (!this.calendar.isEditable) {
				this.currentTimeIsSet = true
				return
			}

			const savedOldScroll = sessionStorage.getItem(`schedule-table-${this.calendarUid}-scroll`)
			if (savedOldScroll) {
				this.setScrollWithoutTransition()
				return
			} 
			const action = () => {
				if (!this.slotCollection.isLoaded)
					return setTimeout(action, 100)
				const tableElem = this.activeEditableTable()
				if (!tableElem) {
					this.currentTimeIsSet = true
					return
				}
				const slotToScroll = this.getSlotToScroll()
				const today = new Date()
				today.setHours(0, 0, 0, 0)
				const isContainToday = this.loadedDates.selected.include(today, false)
				
				let scrollTop
				if (slotToScroll) {
					
					// Scroll to the closest slot on the table
				
					scrollTop = this.calcScrollToSlot(slotToScroll)
				} else if (isContainToday) {
				
					// Scroll to the red line if it is visible

					scrollTop = this.calcScrollToRedLine(tableElem)
				}

				if (scrollTop == undefined) {
					this.currentTimeIsSet = true
					return
				}

				tableElem.scrollTo({top: scrollTop, behavior: 'smooth'})
			}
			action()
		},
		setScrollWithoutTransition(){
			let savedTableScroll = sessionStorage.getItem(`schedule-table-${this.calendarUid}-scroll`)
			sessionStorage.removeItem(`schedule-table-${this.calendarUid}-scroll`)
			if (!savedTableScroll)
				return
			let scrollTop = Number(savedTableScroll)
			let activeTable = this.activeEditableTable()
			if (!activeTable)
				return

			activeTable.scrollTop = scrollTop
			this.currentTimeIsSet = true
		},
		getSlotToScroll(){
			const from = this.loadedDates.selected.start.date			
			const fromIsPast = dateIsPast(from)
			const to = this.loadedDates.selected.end.date
			const toIsPast = dateIsPast(to) && !isEqualDate(to, new Date())

			// Get slots that visible on the selected area on the table
			const currentTableSlots = this.slotCollection.getByRange(from, to, {sortByTime: true})
									
			// Sort by time
			const slotsTimes = currentTableSlots
			.map(slot => {
				const startDate = slot.period.start.date
				const id = slot.index
				const minutes = startDate.getHours() * 60 + startDate.getMinutes()
				return { id, minutes, slot}
			}).sort((a, b) => a.minutes - b.minutes)

			// If selected date range is fully past or is fully future 
			if (fromIsPast == toIsPast){
				if (slotsTimes.length == 0)
					return undefined
				const firstVisibleSlot = slotsTimes[0].slot
				return firstVisibleSlot
			}
			
			// Selected date range contain today
			const currentTime = new Date()
			const todayNotPastSlot = currentTableSlots.find(slot => {
				const startDate = slot.period.start.date
				return isEqualDate(startDate, currentTime) && !dateIsPast(startDate)
			})
			return todayNotPastSlot	
		},

		calcScrollToSlot(slot){
			const table = this.activeEditableTable()
			const tableScroll = table.scrollTop
			if (!table)
				return 0
			const tableHeight = table.clientHeight

			let targetPosY = this.getSlotPosition(slot) + this.getSlotHeight(slot) / 2 
			if (targetPosY == 0)
				return 0
			

			const staticMenu = document.querySelector('#static__menu')
			const staticMenuHeight = staticMenu ? staticMenu.clientHeight : 0

			// Part of the table hidden by the static menu
			targetPosY -= (tableHeight - staticMenuHeight) / 2
			if (Math.abs(targetPosY - tableScroll) < 10)
				return 0
			
			return targetPosY
		},

		setPageActivity(activity){
			this.$store.dispatch('set-page-activity', activity)
		},

		submitSlot(slotData){
			this.setPageActivity(null)

			if (!slotData)
				return

			const {hour, minute, date} = slotData

			if (!this.isEditable)
				return

			const slotStartDate = new Date(date)
			slotStartDate.setHours(hour, minute, 0, 0)
			if (this.calendar.IsCommercial && dateIsPast(slotStartDate))
				return

			// Create temp slot for the UI
			const userId = this.$store.getters.userId
			const newSlot = new Slot(slotStartDate, 60, {
				id : this.addedSlots * -1,
				isQuestionSlot: true,
				hostId: userId,
			})
			const browserTZ = this.$store.getters.browserTimezone
			const calendarTZ = this.$store.getters.calendarTimezone
			const isProCalendar = this.calendar.IsCommercial

			this.slotCollection.addQuestionSlot(newSlot)
			this.slotCollection.submitSlot(newSlot, browserTZ, calendarTZ, isProCalendar)
			.then((slots) => {
				if (!slots)
					return
				const addedSlot = slots[0]
				const neighbors = this.slotCollection.getNeighbors(addedSlot)
				if (!neighbors)
					return
				// If new slot has neighbors, update them
				if (neighbors["top"])
					eventBroker.$emit(`slot-card-${neighbors["top"]}`, 'update-neighbors')
				if (neighbors["bot"])
					eventBroker.$emit(`slot-card-${neighbors["bot"]}`, 'update-neighbors')
			})
			this.addedSlots += 1
		},

		activeEditableTable(){
			const activeCalendar = this.$el.querySelector('.calendar-v2')
			if (!activeCalendar)
				return null
			const activeCalendarTable = activeCalendar.querySelector('.calendar-v2-table')
			if (!activeCalendarTable)
				return null
			return activeCalendarTable
		},

		calcScrollToRedLine(table){
			const tableRedLine = table.querySelector('.red__line')
			if (!tableRedLine)
				return 0
			const tableRedLineTop = tableRedLine.style["top"].replace('px', '')
			if (Number(tableRedLineTop) == NaN)
				return 0

			const staticMenu = document.querySelector('#static__menu')
			const staticMenuHeight = staticMenu ? staticMenu.clientHeight : 0

			return Number(tableRedLineTop) - (table.clientHeight) / 2 + staticMenuHeight
		},

		getSlotHeight(slot) {
			return Math.floor(this.editTableCellHeight * slot.period.duration / 60)
		},

		getSlotPosition(slot) {
			let {hour, minute} = this.workTime.startTime()
			let workStartAlign = this.topPositionByTime(hour, minute)
			let slotStart = {
				hour: slot.period.start.date.getHours(),
				minute: slot.period.start.date.getMinutes(),
			}
			let relativeTop = this.topPositionByTime(slotStart.hour, slotStart.minute) 
			return relativeTop - workStartAlign
		},

		topPositionByTime(hour, minute){
			let topPosition = 0
			topPosition += hour * this.editTableCellHeight
			topPosition += minute * this.editTableCellHeight / 60
			return Math.floor(topPosition)
		},

		onSelectDate(date){
			this.$emit('on-select-date', date)
		},
		syncTableTime(){
			if (!this.isEditable)
				return
			let allCarouselSlides = Array.from(this.$el.querySelectorAll('.carousel-slide'))
			let activeCarouselSlide = allCarouselSlides.find(slide => {
				return slide.classList.contains('is-active')
			})
			if (!activeCarouselSlide)
				return
			
			let anotherCarouselSlides = allCarouselSlides.filter(slide => !slide.classList.contains('is-active'))
			let activeCalendarTable = activeCarouselSlide.querySelector('.calendar-v2')
			if (!activeCalendarTable)
				return
			anotherCarouselSlides
				.map(slide => slide.querySelector('.calendar-v2'))
				.forEach(table => {
					if (table.scrollTop == activeCalendarTable.scrollTop)
						return
					table.scrollTop = activeCalendarTable.scrollTop
					table.classList.add('no-scroll')
				})
		},
		makeCalendarScrollable(){
			Array.from(this.$el.querySelectorAll('.carousel-slide'))
				.map(slide => slide.querySelector('.calendar-v2'))
				.forEach(table => table.classList.remove('no-scroll'))	
		},
		checkIntersectionWithBasket({posY}){
			const newSlotPosY = posY
			const deleteBasket = this.$el.querySelector('.delete-slot')
			if (!deleteBasket)
				return
			const deleteBasketRect = deleteBasket.getBoundingClientRect()
			const containerRect = this.$el.getBoundingClientRect()
			const deleteBasketPosY = deleteBasketRect.top - containerRect.top
			const difference = Math.abs(newSlotPosY - deleteBasketPosY)
			this.isDeletePreparation = difference < this.editTableCellHeight * 0.9
		},
	},
}
</script>

<style>

#schedule-table {
	position: relative;
	width: 100%;
	flex: 1;
	overflow: hidden;
	max-height: 100%;
}

.calendar-schedule {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.table-varnish {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
</style>